import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/locales'
import lsService from "@/services/lsService";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: lsService.getLocale()
  },

  mutations: {
    setLocale (state, locale) {
      state.locale = locale;
      lsService.setLocale(locale);
      i18n.locale = locale;
    }
  }
})
