<template>
  <div class="cookies-privacy-policy">
    <div class="cookies-privacy-policy__container container">
      <p class="text" :class="{ru: locale === 'RU'}">
        {{ $t('cookies-privacy-policy.pretext') }}
        <a target="_blank" href="/docs/privacy_policy.pdf">{{ $t('cookies-privacy-policy.privacy-policy') }}</a>.
        {{ $t('cookies-privacy-policy.posttext') }}
      </p>
      <button class="accept" v-t="'cookies-privacy-policy.accept'" @click="$emit('accept')" />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CookiesPrivacyPolicy",

  computed: {
    ...mapState(['locale'])
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/cookies-privacy-policy';
</style>