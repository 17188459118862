<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <GetFreePacks />
    <transition name="from-bottom" mode="out-in">
      <CookiesPrivacyPolicy v-if="!cookiesPrivacyPolicyAccepted" @accept="onCookiesPrivacyPolicyAccept" />
    </transition>
  </div>
</template>

<script>
import {setMetadata} from "@/services/metadataManager";
import CookiesPrivacyPolicy from "@/components/CookiesPrivacyPolicy";

export default {
  name: 'App',

  data() {
    return {
      cookiesPrivacyPolicyAccepted: true
    }
  },

  created() {
    setMetadata();

    if (localStorage.getItem('cookies_accepted') !== 'true')
      setTimeout(() => this.cookiesPrivacyPolicyAccepted = false, 1000);
  },

  components: {
    CookiesPrivacyPolicy,
    Header: () => import('@/components/Header'),
    Footer: () => import('@/components/Footer'),
    GetFreePacks: () => import('@/components/GetFreePacks')
  },

  methods: {
    onCookiesPrivacyPolicyAccept() {
      localStorage.setItem('cookies_accepted', 'true');
      this.cookiesPrivacyPolicyAccepted = true;
    }
  }
}
</script>
