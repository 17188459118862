import i18n from '../locales'

// sets the correct metadata according to the current language
export function setMetadata() {
  document.title = i18n.t('metadata.title');
  document.querySelector('meta[name=description]').setAttribute('content', i18n.t('metadata.description'))
  document.querySelector('meta[property="og:locale"]').setAttribute('content', i18n.t('metadata.locale'))
  document.querySelector('meta[property="og:title"]').setAttribute('content', i18n.t('metadata.title'))
  document.querySelector('meta[property="og:description"]').setAttribute('content', i18n.t('metadata.description'))
}
