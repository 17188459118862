import moment from 'moment';
import getUserLocale from 'get-user-locale';
import {setMetadata} from "@/services/metadataManager";

export const locales = ['EN', 'RU'];

const lsService = {
  getLocale: () => {
    let locale = localStorage.getItem('language');
    if (!locale || !locales.includes(locale)) {
      locale = getUserLocale().slice(0, 2).toUpperCase();
    }
    locale = locales.includes(locale) ? locale : locales[0];
    lsService.setLocale(locale);
    return locale;
  },
  setLocale: locale => {
    localStorage.setItem('language', locale);
    moment.locale(locale);
    setTimeout(() => setMetadata(), 1)
  }
};

export default lsService;
