import Vue from 'vue';
import App from './App';
import store from './store'
import router from './router'
import './assets/scss/app.scss'
import i18n from "./locales";
import lsService from "./services/lsService";
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import { Swiper as SwiperClass, Mousewheel, Autoplay, Controller, Pagination, Scrollbar, Keyboard } from 'swiper/js/swiper.esm'
import 'swiper/css/swiper.css'
import vClickOutside from 'v-click-outside'
import VBodyScrollLock from 'v-body-scroll-lock'

Vue.config.productionTip = false

i18n.locale = lsService.getLocale();

SwiperClass.use([Mousewheel, Autoplay, Controller, Pagination, Scrollbar, Keyboard]);
Vue.use(getAwesomeSwiper(SwiperClass));
Vue.use(vClickOutside)
Vue.use(VBodyScrollLock)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
